body {
  font-family: Arial, Helvetica, sans-serif;
}

/*result card start*/
.report-header {
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 20px;
}

.report-header table tr td {
  border-collapse: collapse;
}



.report-header table tr td .logo .company h3 {
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 18pt;
  font-weight: bold;
}

.report-header table tr td .logo .company p {
  margin: 0;
  font-size: 9pt;
}

.report-header table tr td .header-right {
  text-align: right;
}

.report-header table tr td .header-right tr td {
  font-size: 10pt;
}

.report-header table tr td .header-right tr td:last-child {
  width: 30px;
}

.report-header table tr td .header-right tr td i {
  font-size: 12pt;
}

.table-summary .col1 {
  width: 60%;
  vertical-align: top;
}

.table-summary .col2 {
  padding-left: 10pt;
  width: 40%;
  vertical-align: top;
}

.table-summary .report-table tr:last-child td {
  border-bottom: none;
}

.table-summary .report-table td {
  padding: 3pt 6pt;
  font-size: 9pt;
}

.report-stock-wrap {
  display: flex;
  flex-wrap: wrap;
}

.report-stock-wrap .report-stock-item {
  padding: 6px 0;
  width: 50%;
  border-bottom: 1px solid;
  font-size: 10pt;
}

.report-stock-wrap .report-stock-item h2 {
  font-size: 10pt;
  font-weight: bold;
  margin: 0 0 2pt 0;
  text-transform: uppercase;
}

.report-stock-wrap .report-stock-item:nth-child(even) {
  border-left: 1px solid;
  padding-left: 20px;
}

.report-stock-wrap .report-stock-item:last-child {
  border-bottom: none;
}

.report-stock-wrap .report-stock-item:nth-last-child(2) {
  border-bottom: none;
}

.report-date-wise-summary th,
.report-date-wise-summary td {
  font-size: 8pt !important;
  padding: 5pt !important;
}

.print-body {
  background-color: #212121;
  font-size: 12pt;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 0 0.5cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.report-title {
  font-family: "Inter", sans-serif;
  font-size: 20pt;
  font-weight: bold;
}

.report-title.bbottom {
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.report-section-title {
  font-family: "Inter", sans-serif;
  font-size: 12pt;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.subpage {
  padding: 0.5cm 0.5cm 0 0.5cm;
  min-height: 256mm;
  page-break-after: always;
}

.subpage>h1 {
  font-family: "Inter", sans-serif;
  font-size: 15pt;
  font-weight: bold;
}

.subpage table {
  width: 100%;
  border-collapse: collapse;
}

@page {
  size: A4;
  margin: 0;
}

.report-table thead {
  background-color: rgba(0, 0, 0, 0.2);
}

.report-table tfoot {
  background-color: rgba(0, 0, 0, 0.1);
}

.report-table tfoot td {
  border-bottom: none;
}

.report-table th {
  font-size: 10pt;
  padding: 5pt 10pt;
  text-align: left;
}

.report-table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 10pt;
  padding: 5pt 10pt;
}

.report-table .current-balance {
  width: 80px;
  text-align: right;
}

.report-table .actype {
  width: 135px;
}

.smiley {
  text-align: center;
  padding: 5pt 3pt;
}

.smiley img {
  width: 20px;
}

.smiley span {
  display: block;
  font-size: 8pt;
}

table.position-table {
  border: 1px solid;
  margin-top: 20px;
  height: 110px;
}


@media print {
  body {
    background-color: #FFF !important;
    -webkit-print-color-adjust: exact;
  }

  .print-body {
    background-color: #FFF !important;
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .report-table thead {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-print-color-adjust: exact !important;
  }

  .report-table tfoot {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-print-color-adjust: exact !important;
  }
}

.result-card table {
  width: 100%;
  border-collapse: collapse;
}

.result-card .school-head {
  text-align: center;
}

.result-card .school-head h2 {
  font-size: 16pt;
  margin: 0;
}

.result-card .school-head h4 {
  font-size: 11pt;
  margin: 0;
}

.option {
  display: flex;
  font-size: 10pt;
}

.option .label {
  font-weight: bold;
  white-space: nowrap;
}

.option .value {
  border-bottom: 1px solid;
  flex-grow: 2;
  padding-left: 10pt;
  white-space: nowrap;
}

.student-info-header {
  border: 1px solid;
  margin-top: 10pt;
  padding: 10pt;
}

.student-info-header table td {
  padding: 3pt 0;
}

.result-card-body .table-bordered {
  font-size: 9pt;
}

.result-card-body thead {
  background-color: rgba(0, 0, 0, 0.08);
}

.result-card-body th,
.result-card-body td {
  padding: 2pt 2pt;
  min-height: 40pt;
}

.result-card-body tbody tr strong {
  text-align: center;
  line-height: 11pt;
  display: block;
}

.result-card-body tbody td.marks {
  /*min-width: 102px;*/
}

.result-card-body tbody td.subject {
  min-width: 133px;
}

.feedback td {
  font-size: 9pt;
  padding: 0.8pt;
  white-space: nowrap;
}

.learning-starts thead {
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  font-weight: 600;
}

.learning-starts td {
  padding: 0.8pt 5pt;
  white-space: nowrap;
}

.learning-starts tbody tr td:nth-child(1) i {
  font-size: 12pt;
}

.learning-starts tbody tr td:nth-child(2) i {
  font-size: 16pt;
}

.learning-starts tbody tr td:nth-child(3) i {
  font-size: 20pt;
}

.learning-starts tbody tr td:nth-child(4) i {
  font-size: 24pt;
}

.learning-starts tbody tr td:nth-child(5) i {
  font-size: 28pt;
}

.position-table td {
  text-align: center;
}

.position-table .position {
  /*width: 90px;*/
  height: 35px;
  border: 1px solid;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stars-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.stars-list li {
  display: flex;
  align-items: center;
  padding-left: 20pt;
  height: 32px;
}

.stars-list li i {
  margin-right: 10pt;
  width: 50px;
  text-align: center;
}

.stars-list li span {
  font-size: 10pt;
}

.stars-list li:nth-child(1) i {
  font-size: 12pt;
}

.stars-list li:nth-child(2) i {
  font-size: 16pt;
}

.stars-list li:nth-child(3) i {
  font-size: 20pt;
}

.stars-list li:nth-child(4) i {
  font-size: 24pt;
}

.stars-list li:nth-child(5) i {
  font-size: 28pt;
}

.thead {
  background-color: rgba(0, 0, 0, 0.08);
  font-weight: bold;
}

.remarks {
  border: 1px solid;
  text-align: center;
  padding: 10pt;
  margin-top: 10pt;
  height: 80pt;
}

.remarks h4 {
  font-size: 12pt;
  font-weight: bold;
}

.rc-footer {
  text-align: center;
  margin-top: 60px;
}

.rc-footer h4 {
  font-size: 10pt;
  font-weight: bold;
}

.greyback {
  background-color: #e3e0e0;
  font-size: 11px;
}

@media print() {

  .greyback {
    background-color: #e3e0e0 !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
  }

  .greyback td {
    background-color: #e3e0e0 !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
  }
}

/*result card end*/