.page {
    box-sizing: border-box;
    width: 210mm;
    min-height: 277mm;
    padding: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
    transform-origin: 50% 50%;
    color: #000;
    page-break-after: always;

    &:last-child {
        page-break-after: auto;
    }

    &.legal {
        width: 215.9mm;
        min-height: 355.6mm;

        &.landscape {
            width: 355.6mm;
            min-height: 215.9mm;
        }
    }

    &.a4 {
        &.landscape {
            width: 297mm;
            min-height: 210mm;
        }
    }

    .content {
        box-sizing: border-box;
        width: 100%;
        min-height: 277mm;
        padding: 4mm;
        margin: 0;
        background-color: #fff;

        table {
            thead {
                display: table-header-group;

            }
        }
    }
}


.page-break {
    page-break-before: always;
}

.page-break-after {
    page-break-after: always;
}

.table-print.table-sm {
    border-color: #000;

    td,
    th {
        font-size: 11px;
        padding: 4px !important;
        vertical-align: middle;
        color: #000;
    }

    td {
        padding: 2px !important;
    }
}

.table-payslip {
    thead {
        td {
            font-weight: bold;
        }
    }

    td {
        border: 1px solid;
        padding: 3px;
        font-size: 14px;
    }
}

.invoice-page {
    box-sizing: border-box;
    width: 210mm;
    height: 277mm;
    padding: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
    transform-origin: 50% 50%;
    color: #000;
    page-break-after: auto;

    &:last-child {
        page-break-after: auto;
    }

    &.legal {
        width: 215.9mm;
        min-height: 355.6mm;

        &.landscape {
            width: 355.6mm;
            min-height: 215.9mm;
        }
    }

    &.a4 {
        &.landscape {
            width: 297mm;
            min-height: 210mm;
        }
    }

    .content {
        box-sizing: border-box;
        width: 100%;
        height: 277mm;
        padding: 10mm 4mm;
        margin: 0;
        background-color: #fff;

        table {
            thead {
                display: table-header-group;

            }
        }
    }

    .invoice-header {
        img {
            width: 300px;
        }

        h1 {
            border-bottom: 2px solid #000;
            display: inline-flex;
            font-size: 22pt;
            font-weight: 600;
        }

        .date {
            display: flex;
            gap: 8px;
            align-items: flex-end;
            justify-content: flex-end;

            span {
                color: #adadad;
            }

            p {
                border-bottom: 1px solid #c9c9c9;
                margin: 0;
                margin-bottom: 6px;
                padding-right: 15px;
            }
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;

            li {
                border: 1px solid #2f93c8;
                width: 60%;
                padding: 5px 10px;
                text-align: center;
                color: #2f93c8;
                font-weight: 600;
                border-radius: 5px;
            }
        }
    }

    .invoice-content {
        padding: 30px;
    }

    .table {
        margin: 40px 0;

        thead {
            tr {
                background: rgb(200, 200, 200);
            }
        }

        tbody {
            tr {
                &:first-child {
                    height: 250px;
                }
            }
        }
    }

    .invoice-footer {
        ul {

            list-style-type: none;
            border-left: 2px solid $secondary;
            padding-left: 10px;
            margin: 0;

            li {
                color: #afafaf;
            }
        }
    }
}

.salary-sheet {
    ol {
        li {
            &::marker {
                font-weight: 700;
            }
        }
    }

    .salary-box {
        border: 1px solid #000;
        padding: 10px;
        margin: 10px 0;
        ol{
            margin: 0;
        }
    }
}

@media print {


    body {
        background-color: #FFF;
    }

    .page {
        box-shadow: none;
        size: auto;
    }

    .page-break {
        page-break-before: always !important;
    }


    .page-break-after {
        page-break-after: always !important;
    }

    .print-main-table {
        >thead {
            td {
                padding: 0;
            }
        }

        >tbody {
            td {
                padding: 0;
            }
        }
    }

    // tr{
    //     border: 1px solid black;
    // }

    .content {

        // top: 80px;
        // position: relative;
        table {
            thead {
                display: table-header-group !important;

            }
        }
    }

    body {
        padding: 0 !important;
    }
}

@page {
    size: auto !important;
}