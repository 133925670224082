.table {
 
    &.table-theme {
        thead {
            @include bgSecondary(0.08);
        }

        tbody {
            tr {
                td {
                    font-size: .9rem;
                    vertical-align: middle;
                }
            }
        }
    }

    thead {

        tr {
            th {
                font-size: .9rem;
            }
        }
    }

    tbody {}
}

.table-actions {
    a {
        font-size: 1.3rem;
    }
}


.table> :not(:first-child) {
    border-top: 0;
}

.va-middle {

    td,
    th {
        vertical-align: middle;
    }
}

.table-add-sale {
    .col-input {
        max-width: 70px;
    }
}

.dropdown-table-actions {
    >a {
        font-size: 1.3rem;
    }

    .dropdown-menu {
        a {
            color: black;
            font-size: .9rem;
            display: flex;
            align-items: center;

            &:hover {
                @include bgSecondary(0.1);

                i {
                    color: $secondary;
                }
            }

            i {
                margin-right: 0.5rem;
            }
        }
    }
}

.table-condensed {
    thead {
        tr {
            th {
                font-size: .8rem;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: .8rem;
            }
        }
    }
}

.table-sticky {
    thead {


        th {
            position: sticky;
            top: -1rem;
            background-color: #D9F0F9;
            z-index: 1;
        }
    }
}

.status {
    display: flex;
    margin: 0;
    padding: 0px;

    span {
        border-radius: 2px;
        display: flex;
        width: 25px;
        height: 25px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .green {
        background-color: #04D9A1;
        color: white;
    }

    .blue {
        background-color: $secondary;
        color: white;
    }

    .red {
        background-color: $red;
        color: white;
    }
}



.inline-action {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0px;


    li {
        padding: 0px 4px;
        list-style: none !important;

        button,
        a {
            border-radius: 50% !important;
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            transition: 0.5s;
        }

        .green {
            border: 1px solid #04D9A1;
            background: #04D9A1;
            color: #ffffff;

            &:hover {
                border: 1px solid #04D9A1;
                background: transparent;
                color: #04D9A1;
            }
        }

        .blue {
            border: 1px solid #05acff;
            background: #05acff;
            color: #ffffff;

            &:hover {
                border: 1px solid #05acff;
                background: transparent;
                color: #05acff;
            }
        }

        .red {
            border: 1px solid #ff002b;
            background: #ff002b;
            color: #ffffff;

            &:hover {
                border: 1px solid #ff002b;
                background: transparent;
                color: #ff002b;
            }
        }
    }
}

.table {
    .bg-green {
        background: #04D9A1;
        color: white !important;
    }

    .bg-blue {
        background: #05acff;
        color: white !important;
    }

    .bg-red {
        background: #ff002b;
        color: white !important;
    }
}

.table-reset {
    table {
        width: 100%;
    }
}

.table-attendence {
    font-size: 12px;

    td {
        border: 1px solid;
        white-space: nowrap;
        padding: 1px 2px;
    }

    tr {
        &:first-child {
            td {
                font-weight: bold;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        &:nth-child(2) {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.table-attendance {

    td {
        i {
            font-size: 18px;
            line-height: 0;
        }
    }


}

.white-space-wrap {
    td {

        white-space: pre-wrap;
    }
}

.h200 {
    .page {
        min-height: 200.9mm !important;
        .content{
            min-height:200.9mm !important;
        }
        .legal {
            min-height: 200.9mm !important;

            .landscape {
                min-height: 200.9mm !important;
            }
        }
    }
}

.print-main-table{
    width: 100%;
}

@media screen and (max-width:1000px) {
    .card-body {
        overflow-y: hidden;
        overflow-x: scroll;
        width: 100%;

        .table {}
    }

    // .table_card {
    //     overflow-y: hidden;
    //     overflow-x: scroll;
    //     width: 100%;

    //     .table {}
    // }
}