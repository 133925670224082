.notifications {


    .dropdown-toggle {
        border: transparent;
        @include flexcenter;
        background-color: #fff !important;
        position: relative;

        svg {
            stroke: #525866;
            width: 30px;
        }

        .notifications-icon {
            &.active {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $primary;
                @include flexcenter;
                flex-shrink: 0;
                position: absolute;
                top: 0%;
                right: 15%;
                transform: translate(0, 0);
            }
        }


        &::after {
            display: none;
        }
    }

    svg {
        width: 20px;
    }

    .notifications-wrap {
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
        width: 360px;
        min-width: 260px;
        padding: 0 0 5px 0 !important;
        overflow: hidden;
    }

    .noifications-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;

        .notifications_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 15px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            >label {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
            }

            >ul {
                display: flex;
                padding: 0;
                margin: 0;
                list-style-type: none;
                align-items: center;
                gap: 20px;

                li {
                    >div {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
                    }

                    span {
                        color: #525966;
                        font-size: 12px;
                        font-weight: 700;
                    }

                    svg {
                        color: #525966;
                    }

                    &:hover,
                    &:active {
                        span {
                            color: $primary;
                        }

                        svg {
                            stroke: $primary;
                        }
                    }
                }
            }
        }

        .notification-list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            list-style-type: none;
            padding: 0;
            margin: 0;
            height: 75vh;
            overflow-y: scroll;
            width: 100%;

            li {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                padding: 10px 10px 10px 30px;
                position: relative;
                width: 100%;

                .massage-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 5px;

                    h5 {
                        color: #000;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16px;
                        margin: 0;
                        color: #525866;
                    }

                    p {
                        margin: 0;
                        font-size: 13px;
                        line-height: 16px;
                    }

                    span {
                        color: #525866;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }


                .indicator {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    @include flexcenter;
                    flex-shrink: 0;
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    transform: translate(50%, 0);

                    &.active {
                        background-color: $primary;
                    }

                }

                >svg {
                    cursor: pointer;
                    flex-shrink: 0;
                    width: 17px;
                    z-index: 5;
                }

                &:hover {
                    background: #F5F7FA !important;
                }

                &.active {
                    background-color: #F5F7FA;
                }
            }
        }

    }

    .dropdown-menu {
        padding: 10px;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            a {
                color: #525866;
                transition: 0.3s all;

                svg {
                    width: 20px;
                    margin-right: 10px;
                }

                &:hover {
                    color: $primary;
                    transition: width .3;
                }
            }

        }

    }
}